<template>
  <b-card-actions
    ref="cardA"
    no-actions
    no-body
    @refresh="doLoadData('cardA')"
  >
    <b-card class="mb-2" no-body>
      <!-- !! Table Header -->
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" cols="12" sm="12" md="9" lg="4">
            <HeroVueSelect
              id="client-id"
              v-model="clientId"
              placeholder="Select Client Email"
              :clearable="true"
              :options="$store.getters['ClientOptions/getOptions']"
            />
          </b-col>
          <b-col
            v-if="$can('Edit', 'Clients')"
            class="mt-1" cols="12" sm="12" md="3" offset-lg="0" lg="2"
          >
            <HeroButtonModal
              v-b-modal="'attach-client-modal'"
              type="button"
              variant="primary"
              :disabled="!clientId"
            >
              <feather-icon icon="TagIcon" size="16" />
              Attach
            </HeroButtonModal>
          </b-col>
          <b-col
            v-if="$can('Edit', 'Clients')"
            class="mt-1" cols="6" sm="6" offset-md="6" md="3" offset-lg="2" lg="2"
          >
            <HeroButtonModal
              v-b-modal="'update-client-modal'"
              type="button"
              variant="primary"
              :disabled="!clientId"
            >
              <feather-icon icon="UserIcon" size="16" />
              Update
            </HeroButtonModal>
          </b-col>
          <b-col
            v-if="$can('Edit', 'Clients')"
            class="mt-1" cols="6" sm="6" md="3" lg="2"
          >
            <HeroButtonModal
              v-b-modal="'create-client-modal'"
              type="button"
              variant="primary"
            >
              <feather-icon icon="UserIcon" size="16" />
              Create
            </HeroButtonModal>
          </b-col>
        </b-row>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="doTableSort"
          >
            <!-- !! Action Column -->
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none action-button"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('Edit', 'Clients')"
                  @click="doDetachClient(data.item.id, data.item.name)"
                >
                  <feather-icon
                    icon="TagIcon"
                    class="mr-50"
                  />
                  <span>Detach Client</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <!-- !! Start Date Column -->
            <template #cell(actual_campaign_start_date)="data">
              <div>{{ formatEnglishDateDefault(data.item.actual_campaign_start_date.substr(0, 10)) }}</div>
            </template>

            <!-- !! End Date Column -->
            <template #cell(actual_campaign_end_date)="data">
              <div>{{ formatEnglishDateDefault(data.item.actual_campaign_end_date.substr(0, 10)) }}</div>
            </template>

            <!-- !! Account Manager Column -->
            <template #cell(account_manager)="data">
              <div v-if="data.item.account_manager !== null">
                {{ data.item.account_manager.first_name }} {{ data.item.account_manager.last_name }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>

      <!-- !! Modal -->
      <CreateClientModal @success="doSuccess" />
      <UpdateClientModal :client-id="clientId" @success="doSuccess" />
      <AttachClientModal :client-id="clientId" @success="doLoadData" />

    </b-card>
  </b-card-actions>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import DataFormatService from '@/services/DataFormatService'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import CreateClientModal from '@/views/heroai/settings/clients/components/CreateClientModal.vue'
import UpdateClientModal from '@/views/heroai/settings/clients/components/UpdateClientModal.vue'
import AttachClientModal from '@/views/heroai/settings/clients/components/AttachClientModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BCardActions,
    HeroButtonModal,
    HeroVueSelect,
    HeroTablePagination,
    HeroTableStatus,
    CreateClientModal,
    UpdateClientModal,
    AttachClientModal,
  },
  data() {
    return {
      editId: '',
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 100,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            ...this.$can('Edit', 'Clients') && {
              label: 'Action',
              key: 'action',
              stickyColumn: false,
            },
          },
          {
            label: 'Campaign Name',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Start Date',
            key: 'actual_campaign_start_date',
            sortable: true,
          },
          {
            label: 'End Date',
            key: 'actual_campaign_end_date',
            sortable: true,
          },
          {
            label: 'Account Manager',
            key: 'account_manager',
            sortable: false,
          },
        ],
      },

      clientId: '',
      campaignId: '',

      // Import Methods
      formatEnglishDateDefault: DataFormatService.formatEnglishDateDefault,
    }
  },
  watch: {
    async clientId() {
      await this.doLoadData()
    },
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      await this.$store.dispatch('ClientOptions/fetchOptions')

      if (this.clientId === '') {
        return false
      }

      this.$refs.cardA.showLoading = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const url = `client/list/client/${this.clientId}?perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}`
        const response = await axios.get(url, axiosConfig)

        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)
        this.items = response.data.data.data
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.tableConfig.totalRows = 0
      }

      this.$refs.cardA.showLoading = false
      return false
    },

    async doDetachClient(campaignId, campaignName) {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      alertResult = await this.$swal({ ...SweetAlert.confirm, text: `Do you want to detach the client from "${campaignName}"?` })

      if (alertResult.value) {
        this.$refs.cardA.showLoading = true

        const body = {
          campaign_id: campaignId,
          user_id: this.clientId,
        }

        try {
          const response = await axios.post('/client/detach-client/', body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          await this.doLoadData()
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }
        }

        this.$refs.cardA.showLoading = false
      }
    },

    doSuccess(clientId) {
      this.clientId = clientId
    },
  },
}
</script>

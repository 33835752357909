<template>
  <b-modal
    id="update-client-modal"
    ref="update-client-modal"
    title="Update Client"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! First Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="first-name"
              rules="required"
            >
              <HeroInputText
                id="first-name"
                v-model="client.first_name"
                label="First Name"
                column="4"
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Last Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="last-name"
              rules=""
            >
              <HeroInputText
                id="last-name"
                v-model="client.last_name"
                label="Last Name"
                column="4"
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Email -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required"
            >
              <HeroInputText
                id="email"
                v-model="client.email"
                label="Email"
                column="4"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Password -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="min:8"
            >
              <HeroInputText
                id="password"
                v-model="client.password"
                label="Password"
                column="4"
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Listen Call Record -->
          <b-col cols="12">
            <HeroCheckbox
              id="listen-call-record"
              v-model="client.listen_call_record"
              label="Listen Call Record"
              column="4"
            />
          </b-col>

          <!-- !! Add/Edit Status -->
          <b-col cols="12">
            <HeroCheckbox
              id="edit-status"
              v-model="client.edit_status"
              label="Add/Edit Status"
              column="4"
            />
          </b-col>

          <!-- !! Download -->
          <b-col cols="12">
            <HeroCheckbox
              id="download-leads"
              v-model="client.download_leads"
              label="Download Leads"
              column="4"
            />
          </b-col>

          <!-- !! Active Account -->
          <b-col cols="12">
            <HeroCheckbox
              id="active"
              v-model="client.is_active"
              label="Active Account"
              column="4"
            />
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroCheckbox,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,

      original: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        is_active: true,
        listen_call_record: false,
        edit_status: false,
        download_leads: false,
      },
      client: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        is_active: true,
        listen_call_record: false,
        edit_status: false,
        download_leads: false,
      },
    }
  },
  methods: {
    doCloseModal() {
      this.client = { ...this.original }
      this.$refs['update-client-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const response = await axios.get(`users/${this.clientId}`, axiosConfig)
        const client = response.data.data

        this.client.first_name = client.first_name
        this.client.last_name = client.last_name
        this.client.email = client.email
        this.client.is_active = !!client.is_active

        if (client.roles.length > 0) {
          client.roles.forEach(role => {
            if (role.name === 'Client - Listen Call Record') {
              this.client.listen_call_record = true
            }

            if (role.name === 'Client - Edit Status') {
              this.client.edit_status = true
            }

            if (role.name === 'Client - Download Leads') {
              this.client.download_leads = true
            }
          })
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = {
          first_name: this.client.first_name,
          last_name: this.client.last_name,
          email: this.client.email,
          listen_call_record: this.client.listen_call_record,
          edit_status: this.client.edit_status,
          download_leads: this.client.download_leads,
          is_active: this.client.is_active,
        }

        if (this.client.password) {
          body.password = this.client.password
        }

        try {
          const response = await axios.post(`client/update-client/${this.clientId}`, body, axiosConfig)
          this.$store.commit('ClientOptions/setDoUpdate', true)
          await this.$store.dispatch('ClientOptions/fetchOptions')

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.client = { ...this.original }
            this.$refs['update-client-modal'].hide()
            this.$emit('success', this.clientId)
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
  },
}
</script>
